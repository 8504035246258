import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { EmptyCard } from '@obeta/components/lib/empty-card/EmptyCard'
import { EmptyCardContainer } from '@obeta/components/lib/empty-card/EmptyCardContainer'
import { useHistory } from '@obeta/data/lib/hooks/useHistoryApi'

export const ProjectOverviewEmpty: FC = () => {
  const history = useHistory()
  const { t } = useTranslation()
  return (
    <EmptyCardContainer>
      <EmptyCard
        title={t('PROJECTS.EMPTY_LIST')}
        customAction={{
          title: t('PROJECTS.CREATE_NEW'),
          onClick: () => history.push('/project-details/new'),
        }}
      />
    </EmptyCardContainer>
  )
}
