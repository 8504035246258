import { createContext, FC, useContext } from 'react'
import { ProjectDetailsProject } from '@obeta/models/lib/schema-models/project'
import { noop } from '@obeta/utils/lib/noop'
import {
  ProjectDetailsPage,
  useProjectDetailsPageState,
} from '../hooks/projects/useProjectDetailsPageState'

const defaultValue: ProjectDetailsPage = {
  project: {} as ProjectDetailsProject,
  shippingGroup: null,
  updateOfferId: noop,
  updateShippingGroup: noop,
  updateShippingDataPickup: noop,
  updateShippingType: noop,
  updateStoreId: noop,
  updateDeliveryAddress: noop,
  updateAddressId: noop,
  mutateProject: () => Promise.resolve(true),
  createProject: () => Promise.resolve(true),
  mutateProjectName: () => Promise.resolve(true),
  restoreOriginalSettings: noop,
  updateLocalProjectName: noop,
  hasChanges: false,
}

type Props = { initialProject: ProjectDetailsProject }

const ProjectDetailsContext = createContext<ProjectDetailsPage>(defaultValue)
const Provider = ProjectDetailsContext.Provider

export const ProjectDetailsProvider: FC<Props> = ({ initialProject, children }) => {
  const state = useProjectDetailsPageState(initialProject)
  return <Provider value={{ ...state }}>{children}</Provider>
}

export const useProjectDetailsContext = () => useContext(ProjectDetailsContext)
