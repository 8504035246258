import { FC, useState } from 'react'
import { CardLayout } from '../card-layout/CardLayout'
import {
  CardLayoutIconActionElement,
  CardLayoutIconHeader,
} from '../card-layout/CardLayoutIconHeader'
import { TertiaryButton } from '../custom-button/CustomButton'
import { ReactComponent as TruckIcon } from 'assets/icon/designsystem/local_shipping.svg'
import { ReactComponent as ChevronRightIcon } from 'assets/icon/designsystem/chevron_right.svg'
import { ReactComponent as DeleteIcon } from 'assets/icon/designsystem/delete_outline.svg'
import { ShopRoutes } from '@obeta/utils/lib/variables'
import { ShopLink } from '../link/ShopLink'
import { noop } from '@obeta/utils/lib/noop'
import { useTranslation } from 'react-i18next'
import { Confirm } from '../alert-and-confirm/Confirm'
import { useDeleteProject } from '@obeta/data/lib/hooks/projects/useDeleteProject'
import { useProjectOverviewContext } from '@obeta/data/lib/stores/useProjectOverviewContext'
import dayjs from 'dayjs'

type Props = {
  name: string
  id: string
  offerId?: string
}

export const ProjectCard: FC<Props> = ({ name, id, offerId }) => {
  const { t } = useTranslation()
  const href = `${ShopRoutes.ProjectDetails}/${id}`
  const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = useState(false)
  const deleteProject = useDeleteProject()
  const { refetchProjects, page, setPage } = useProjectOverviewContext()

  const urlOrders = `${ShopRoutes.OrdersList}?${new URLSearchParams({
    projectName: name,
    dateFrom: dayjs().subtract(12, 'month').format('YYYY-MM-DD'),
  }).toString()}`

  const actions: CardLayoutIconActionElement[] = [
    {
      icon: (
        <ShopLink href={urlOrders}>
          <TruckIcon />
        </ShopLink>
      ),
      onClick: noop,
    },
    {
      icon: <DeleteIcon />,
      onClick: () => setShowDeleteConfirmDialog(true),
    },
  ]

  return (
    <>
      <CardLayout
        headerElement={<CardLayoutIconHeader actions={actions} />}
        title={name}
        href={href}
        bottomLineElement={
          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <TertiaryButton rightIcon={<ChevronRightIcon />}>
              {t('PROJECTS.SHOW_DETAILS')}
            </TertiaryButton>
          </div>
        }
      />
      <Confirm
        body={t('PROJECTS.DELETE_DIALOG_BODY')}
        heading={t('PROJECTS.DELETE_DIALOG_HEADING')}
        openConfirmDialog={showDeleteConfirmDialog}
        handleCancel={() => setShowDeleteConfirmDialog(false)}
        handleConfirm={async () => {
          setShowDeleteConfirmDialog(false)
          await deleteProject(id)
          const hasProjects = await refetchProjects()
          if (!hasProjects && page > 1) {
            // navigate one page back, since there are no results on this page anymore
            setPage(page - 1)
          }
        }}
      />
    </>
  )
}
