import { FC, useState } from 'react'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import styles from './ProjectCartPreSelectionForm.module.scss'
import { ProjectCartOfferForm } from './ProjectCartOfferForm'
import { ProjectCartShippingGroupForm } from './ProjectCartShippingGroupForm'
import { ProjectCartOrderDeclarations } from './ProjectCartOrderDeclarations'
import { useProjectDetailsContext } from '@obeta/data/lib/stores/useProjectDetailsContext'
import { SelectionBar } from '@obeta/components/lib/selection-bar/SelectionBar'
import { DarkButton, TertiaryButton } from '@obeta/components/lib/custom-button/CustomButton'
import { ProjectFormVariant } from './ProjectForm'
import { useHistoryBack } from '@obeta/data/lib/hooks/useHistoryBack'
import { ShopRoutes } from '@obeta/utils/lib/variables'
import { Confirm } from '@obeta/components/lib/alert-and-confirm/Confirm'

type Props = { variant: ProjectFormVariant }
export const ProjectCartPreSelectionForm: FC<Props> = ({ variant }) => {
  const [showCancelConfirmDialog, setShowCancelConfirmDialog] = useState(false)
  const { t } = useTranslation()
  const { hasChanges, restoreOriginalSettings, mutateProject, createProject } =
    useProjectDetailsContext()
  const goBack = useHistoryBack({ fallbackTarget: ShopRoutes.ProjectOverview })

  const cancel =
    variant === 'edit' ? restoreOriginalSettings : () => setShowCancelConfirmDialog(true)
  const save = variant === 'edit' ? mutateProject : createProject
  const disabledActions = variant === 'create' ? false : !hasChanges

  return (
    <>
      <div className={styles.container}>
        <Typography className={styles.subHeading} variant="bodyBold">
          {t('PROJECTS.DETAILS.PRE_SELECTION_TITLE')}
        </Typography>
        <div className={styles.form}>
          <ProjectCartOfferForm />
          <ProjectCartShippingGroupForm />
          <ProjectCartOrderDeclarations />
        </div>
        <SelectionBar portalKey="project-details-action-bar">
          <div className={styles.actionBar}>
            <TertiaryButton disabled={disabledActions} onClick={cancel}>
              {t('PROJECTS.DETAILS.CANCEL')}
            </TertiaryButton>
            <DarkButton disabled={disabledActions} onClick={save}>
              {t('PROJECTS.DETAILS.SAVE')}
            </DarkButton>
          </div>
        </SelectionBar>
      </div>
      {variant === 'create' && (
        <Confirm
          body={t('PROJECTS.CANCEL_DIALOG_BODY')}
          heading={t('PROJECTS.CANCEL_DIALOG_HEADING')}
          openConfirmDialog={showCancelConfirmDialog}
          handleCancel={() => setShowCancelConfirmDialog(false)}
          handleConfirm={() => {
            setShowCancelConfirmDialog(false)
            goBack()
          }}
        />
      )}
    </>
  )
}
