import { FC, ReactNode, useState } from 'react'
import { MenuItem, Select, Typography, useTheme } from '@mui/material'
import { useWindowScroll } from '@obeta/data/lib/hooks/useWindowEvent'
import { DropdownOption } from '@obeta/models/lib/models/UI'
import styles from './Dropdown.module.scss'
import clsx from 'clsx'

const DROPDOWN_WIDTH = '10rem'

type DropdownLabelProps = {
  icon?: ReactNode
  label: string
  className?: string
}

const DropdownLabel: FC<DropdownLabelProps> = (props) => {
  const { icon, label, className } = props

  return (
    <div className={styles.label}>
      <div className={styles.labelIcon}>{icon}</div>
      <Typography className={clsx(className, styles.labelText)} variant="bodyBold">
        {label}
      </Typography>
    </div>
  )
}

type Dropdown2Props = {
  innerLabel?: {
    icon?: ReactNode
    label: string
  }
  justifyBetween?: boolean
  options: DropdownOption[]
  outerLabel?: string
  value?: number | string
  width?: string
  onChange: (value: number | string) => void
  dropdownLabelClassName?: string
  disabled?: boolean
}

export const Dropdown2: FC<Dropdown2Props> = (props) => {
  const {
    innerLabel,
    justifyBetween,
    options,
    outerLabel,
    value,
    width,
    onChange,
    dropdownLabelClassName,
    disabled,
  } = props

  const theme = useTheme()

  // Component state
  const [open, setOpen] = useState<boolean>(false)

  // Close dropdown on window scroll
  useWindowScroll(() => {
    open && setOpen(false)
  }, [open])

  return (
    <div className={clsx(styles.dropdown, justifyBetween && styles.dropdownJustifyBetween)}>
      {outerLabel && <Typography typography="body">{outerLabel}</Typography>}
      <Select
        disabled={disabled}
        displayEmpty={value === null || value === undefined}
        renderValue={
          innerLabel && (value === null || value === undefined)
            ? () => (
                <DropdownLabel
                  className={dropdownLabelClassName}
                  icon={innerLabel.icon}
                  label={innerLabel.label}
                />
              )
            : undefined
        }
        sx={{
          // Not working with width attribute
          maxWidth: width ?? DROPDOWN_WIDTH,
          minWidth: width ?? DROPDOWN_WIDTH,
          '.MuiSelect-outlined': {
            width: '100%',
          },
          // Not working with css
          '.MuiOutlinedInput-notchedOutline': { borderColor: theme.palette.grayVariant.light },
        }}
        MenuProps={{
          sx: {
            width: width ?? DROPDOWN_WIDTH,
          },
        }}
        open={open}
        SelectDisplayProps={{
          className: styles.dropdownElement,
        }}
        value={value}
        variant="outlined"
        onChange={(event) => onChange(event.target.value)}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
      >
        {options.map((option) => {
          if (option.value === null || option.value === undefined) return null
          return (
            <MenuItem
              key={option.value}
              classes={{
                root: styles.menuItem,
              }}
              selected={option.value === value}
              value={option.value}
            >
              <Typography
                className={styles.menuItemTitle}
                variant={value === option.value ? 'bodyBold' : undefined}
              >
                {option.label}
              </Typography>
            </MenuItem>
          )
        })}
      </Select>
    </div>
  )
}
