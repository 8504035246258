import { FC } from 'react'
import clsx from 'clsx'
import { Typography } from '@mui/material'
import { Dropdown2 } from '@obeta/components/lib/dropdown2/Dropdown2'
import { useTranslation } from 'react-i18next'
import { useProjectDetailsContext } from '@obeta/data/lib/stores/useProjectDetailsContext'
import { useEntities } from '@obeta/data/lib/hooks'
import { OfferV2 } from '@obeta/models/lib/models'
import formStyles from './ProjectCartPreSelectionForm.module.scss'
import styles from './ProjectCartOfferForm.module.scss'

export const ProjectCartOfferForm: FC = () => {
  const offers = useEntities<OfferV2>('offersv2')
  const { project, updateOfferId } = useProjectDetailsContext()
  const { t } = useTranslation()

  const value = project.offerId.length > 0 ? project.offerId : undefined
  return (
    <div className={clsx(styles.offerContainer, formStyles.card)}>
      <Typography className={formStyles.subHeading} variant="bodyBold">
        {t('PROJECTS.DETAILS.OFFER')}
      </Typography>
      <div>
        <Typography variant="body">{t('PROJECTS.DETAILS.OFFER_INFO_TEXT')}</Typography>
        <Dropdown2
          value={value}
          options={offers.map((offer) => ({ label: offer.offerName, value: offer.id }))}
          onChange={(value) => updateOfferId(value as string)}
          innerLabel={{ label: t('PROJECTS.DETAILS.OFFER_PLACEHOLDER') }}
          dropdownLabelClassName={formStyles.dropdownLabel}
          width="100%"
          disabled={offers.length === 0}
        />
      </div>
    </div>
  )
}
